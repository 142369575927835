











































































import MNotificationVue from "@/mixins/MNotification.vue";
import { Moment } from "moment";
import Vue from "vue";
import { DEFAULT_DATE_FORMAT } from "@constant/date.constant";
import { movementItemService } from "@service/movement.service";
import { FormModel } from "ant-design-vue";
import { MOVEMENT_NOTE } from "@/models/enums/movement.enum";
import { MovementParamsDownload } from "@/models/interface-v2/movement.interface";
import { downloadFile } from "@/helpers/file-reader";

export default Vue.extend({
  name: "ReportMoving",
  components: {
    ReportMovingSelectNote: () => import(/*webpackPrefetch: true*/"./components/ReportMovingSelectNote.vue")
  },
  mixins: [
    MNotificationVue,
  ],
  data() {
    return {
      DEFAULT_DATE_FORMAT,
      form: {
        productCode: undefined as string | undefined,
        productName: undefined as string | undefined,
        productId: "",
        periodFrom: null as Moment | null,
        periodTo: null as Moment | null,
        note: [] as MOVEMENT_NOTE[],
      },
      rules: {
        productCode: [{ required: false, trigger: "blur", message: (): string => this.$t("lbl_validation_required_error").toString() }],
        productName: [{ required: false, trigger: "blur", message: (): string => this.$t("lbl_validation_required_error").toString() }],
        periodFrom: [{ required: true, trigger: "change", message: (): string => this.$t("lbl_validation_required_error").toString() }],
        periodTo: [{ required: true, trigger: "change", message: (): string => this.$t("lbl_validation_required_error").toString() }],
        note: [{ required: false, type: "array", trigger: "blur", message: (): string => this.$t("lbl_validation_required_error").toString() }],
      },
      loading: {
        download: false,
      },
    };
  },
  methods: {
    onSelectProduct({ meta }, source: "productName" | "productCode"): void {
      this.form.productId = meta?.meta.id || "";
      this.form[source] = source === "productName" ? meta?.meta.name || "" : meta?.meta.code || "";
    },
    handleClear(): void {
      const refs = this.$refs.reportMovingForm as FormModel;
      refs.resetFields();
    },
    async handleDownload(): Promise<void> {
      try {
        this.loading.download = true;
        const params: MovementParamsDownload = {
          fromDate: this.form.periodFrom?.format("YYYY-MM-DD") || "",
          noted: this.form.note.join(","),
          productCode: this.form.productCode || "",
          untilDate: this.form.periodTo?.format("YYYY-MM-DD") || "",
        };
        const file = await movementItemService.movementDownload(params);
        downloadFile(new Blob([file]), "report_slow_fast_moving_item.xlsx");
      } catch (error) {
        this.showErrorMessage("");
      } finally {
        this.loading.download = false;
      }
    },
    validateForm(): void {
      const refs = this.$refs.reportMovingForm as FormModel;
      refs.validate((valid: boolean) => {
        if (!valid) return;
        this.handleDownload();
      });
    },
  }
});
