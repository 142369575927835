import { Api } from "@/models/class/api.class";
import { MovementParamsDownload } from "@/models/interface-v2/movement.interface";
import { ErrorResponseModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class MovementItemServices extends HttpClient {
  constructor() {
    super();
  }

  /**
   * download movement item report
   * @param params download param
   * @returns report file
   */
  movementDownload(params: MovementParamsDownload): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.ReportsMovement, { params, responseType: "arraybuffer" })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const movementItemService = new MovementItemServices();
